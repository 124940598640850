
body {
    font-family: "Sora", sans-serif!important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
span {
    font-family: "Sora", sans-serif!important;

}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
}
img, video, iframe {
    max-width: 100%;
    height: auto;
}
*, ::after, ::before {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    background: $gradientDefault;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: 0px 0px 0px 100000vh $black;
}
body {
    margin: 0;
    padding: 0;
    background-color: $bgDefault!important;
    font-family: 'Sora', sans-serif;
}

video {
    border-radius: 10px;
}
.p-skeleton {
    background: #20262b!important;
}
.animate {

}
.fp-heading {
    color: $white;
    font-size: 1.5rem;
    text-align: center;
    max-width: 700px;
    margin: auto;
    margin-bottom: 1.5rem;
    margin-top: 0;
}
.p-dialog {
    background: $bgDefault;
    border: 2px solid $borderPrimary!important;
    border-radius: 12px!important;
    overflow: hidden;
    &-header {
        background: $bgDefault!important;
    }
    &-content {
        background: $bgDefault!important;
    }
    &-title {
        color: $white;
    }
}

.p-confirm-popup {
    background: $black!important;
    border: 3px solid $borderPrimary!important;
    &:before, &:after {
        display: none;
    }
    &-message {
        color: $white;
    }
    .p-button-label.p-c {
        color: $white;
    }
}

.react-player__preview {
    position: relative;
    z-index: 99;
}
.react-player__shadow {
    background: $gradientButton!important;
    border-radius: 50px;
}
.shadow-video {
    box-shadow: 0 0 10px 0 #00000040;
    border-radius: 14px;
}
