
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
// boostrap
@import "./../node_modules/bootstrap/scss/functions";
@import "./../node_modules/bootstrap/scss/variables";
@import "./../node_modules/bootstrap/scss/mixins";
@import "./../node_modules/bootstrap/scss/containers";



// video
@import '~video-react/styles/scss/video-react';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

// Primereact
@import "primereact/dropdown.scss";

// Globals
@import "_variables.scss";
@import "_globals.scss";
@import "_layout.scss";
@import "_animation.scss";

