// background
$bgDefault: #080b0e;

// gradients
$gradientDefault: linear-gradient(
  296deg,
  #00e4b4 0%,
  #12ecc4 14.29%,
  #00c0ca 28.57%,
  #00aed5 42.86%,
  #009ce1 57.14%,
  #008bec 71.43%,
  #0079f7 85.71%,
  #085CFF 100%
);
$gradientDefaultOpacity: linear-gradient(
  296deg,
  #00e4b430 0%,
  #12ecc430 14.29%,
  #00c0ca30 28.57%,
  #00aed530 42.86%,
  #009ce130 57.14%,
  #008bec30 71.43%,
  #0079f730 85.71%,
  #085CFF30 100%
);

$gradientButton: linear-gradient(
  45deg,
  rgba(0, 174, 213, 1) 0%,
  rgba(0, 156, 225, 1) 25%,
  rgba(0, 139, 236, 1) 50%,
  rgba(0, 121, 247, 1) 75%,
  rgba(0, 108, 255, 1) 100%
);
$gradientButtonDarker: linear-gradient(
                45deg,
                rgba(0, 139, 170, 1) 0%,
                rgba(0, 125, 180, 1) 25%,
                rgba(0, 111, 189, 1) 50%,
                rgba(0, 97, 198, 1) 75%,
                rgba(0, 86, 204, 1) 100%
);
$gradientBorder: linear-gradient(270.5deg, #00e4b4 2.11%, #085CFF 99.41%);
$gradientBorderOpacity: linear-gradient(270.5deg, rgba(0, 228, 180, 0.5) 2.11%, rgba(8, 92, 255, 0.5) 99.41%);


// shadows
$blueShadow: #00237c;
$greenShadow: #276677;

// colors
$grayDark: #9e9e9e;
$gray: #b9b9b9;
$grayLight: #6a6a6a;
$black: #0d1216;
$blue: #008bec;
$teal: $gray;
$white: #fbfbfb;
$formWhite: #f6f6f6;
$discord: #5865f2;
$green: #028D02;
$cyan: #12ECC4;

// border
$borderPrimary: #202528;
$borderVideo: #36323F;

// sizing
$xxl: 1440px;
$xl: 1200px;
$lg: 991px;
$md: 776px;
$sm: 576px;
