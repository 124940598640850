// Auth layout
.auth-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    padding: 1rem;
    padding-top: 4rem;
    &-inner {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 10;
        > .card-form {
            position: relative;

            &:after {
                content: "";
                background: $blueShadow;
                border-radius: 50px;
                filter: blur(125px);
                width: 250px;
                height: 250px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: -1;

            }
            &:before {
                content: "";
                background: $greenShadow;
                border-radius: 50px;
                width: 250px;
                height: 250px;
                filter: blur(125px);
                position: absolute;
                right: 0;
                top: 0;
                z-index: -2;
            }
        }
    }
}
